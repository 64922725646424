import React, { useEffect, useContext, useState } from "react";
import QueryString from "query-string";

/* packages */
import { GlobalContext } from "../../lib-atomic-context";
import {
  FetchContextApi,
  FetchContextApiV2,
  LocalStorage,
} from "../../lib-atomic-functions";
/* packages */

/* module */
import Spinner from "../../app-module-section-spinner/default";
/* module */

import CONFIG from "../config";
/* CONSTANTS */
const CMSENDPOINTS = CONFIG.application.cms.api.endpoints;
const CMSMASTERCOGNITOUSER = {
  url: CMSENDPOINTS.mastercongnitouser.url,
  method: CMSENDPOINTS.mastercongnitouser.method,
  headers: CMSENDPOINTS.mastercongnitouser.headers,
  responseType: "json",
};
const CMSMASTERCOUNTRYLANGUAGES = {
  url: CMSENDPOINTS.mastercountrylanguages.url,
  method: CMSENDPOINTS.mastercountrylanguages.method,
  headers: CMSENDPOINTS.mastercountrylanguages.headers,
  responseType: "json",
};
const CMSMASTERCampaign = {
  url: CMSENDPOINTS.campaignlist.url,
  method: CMSENDPOINTS.campaignlist.method,
  headers: CMSENDPOINTS.campaignlist.headers,
  responseType: "json",
};
const Index = (props) => {
  // const [loading, setLoading] = useState(false)
  // const { token, username } = LocalStorage.getLS().strapi
  // const { cognitouser, campaignlist } = useContext(GlobalContext)

  // /* --- reset */
  // const fetchResetExec = async () => {
  //   await cognitouser.dispatch({
  //     type: 'RESET',
  //   })
  //   await campaignlist.dispatch({
  //     type: 'RESET',
  //   })
  //   setLoading(true)
  // }

  // /* --- cognitouser */
  // const fetchCognitoUserExecute = async ({ query }) => {
  //   CMSMASTERCOGNITOUSER.headers.Authorization = 'Bearer ' + token
  //   const [fetchCognitoUser] = FetchContextApiV2(CMSMASTERCOGNITOUSER)
  //   cognitouser.dispatch({ type: 'LOAD' })
  //   const fetchResult = await fetchCognitoUser({ query }, cognitouser)
  //   cognitouser.dispatch({
  //     type: 'SET',
  //     data: fetchResult,
  //   })
  // }
  // /* --- cognitouser */

  // /* --- master campaignlist */
  // const fetchMasterCampaignExecute = async ({ query }) => {
  //   CMSMASTERCampaign.headers.Authorization = 'Bearer ' + token
  //   const [fetchMasterCampaign] = FetchContextApiV2(CMSMASTERCampaign)
  //   campaignlist.dispatch({ type: 'LOAD' })
  //   const fetchResult = await fetchMasterCampaign({ query }, campaignlist)
  //   campaignlist.dispatch({
  //     type: 'SET',
  //     data: fetchResult,
  //   })
  // }

  // useEffect(() => {
  //   console.log('3', loading)
  //   if (loading) {
  //     const query = '?cognitoId=' + username
  //     fetchCognitoUserExecute({ query })
  //   }
  // }, [loading])
  // const initFunc = async () => {
  //   console.log('1')
  //   await fetchResetExec()
  // }

  // useEffect(() => {
  //   initFunc()
  // }, [])

  // useEffect(() => {
  //   if (
  //     loading &&
  //     cognitouser.state.data &&
  //     cognitouser.state.isStatus &&
  //     !cognitouser.state.isLoading
  //   ) {
  //     let query = '?'
  //     // let query = '?_limit=500&'
  //     const mastercountrylist = cognitouser.state.data[0].MasterUCCountries
  //     mastercountrylist.map((country, index) => {
  //       if (index === 0) {
  //         query = query + 'mastercountries.abbr=' + country.mastercountry.abbr
  //       } else {
  //         query = query + '&mastercountries.abbr=' + country.mastercountry.abbr
  //       }
  //     })
  //     fetchMasterCampaignExecute({ query })
  //   }
  // }, [cognitouser.state])

  // const isCognitoUserReady =
  //   cognitouser.state.data &&
  //   cognitouser.state.isStatus &&
  //   !cognitouser.state.isLoading
  //     ? true
  //     : false
  // const isCampaignReady =
  //   campaignlist.state.data &&
  //   campaignlist.state.isStatus &&
  //   !campaignlist.state.isLoading
  //     ? true
  //     : false
  // if (!loading || !isCognitoUserReady || !isCampaignReady) {
  //   return <Spinner.Popup />
  // }

  return props.children;
};

export default Index;
