import pageLogin from "../../../app-auth-page-login";
import pageSessionExpired from "../../../app-section-token-expire-landing";
import pageLogout from "../../../app-auth-page-logout";

/* auth: start */
const moduleAuth = [pageLogin, pageLogout, pageSessionExpired];
/* auth: end */

const modules = {
  auth: moduleAuth,
};

export default modules;
