import pageLogin from "../../../app-auth-page-login";
import pageSessionExpired from "../../../app-section-token-expire-landing";
import pageLogout from "../../../app-auth-page-logout";

import aliasList from "../../../app-alias-page-list";

/* auth: start */
const moduleAuth = [pageLogin, pageLogout, pageSessionExpired];
/* auth: end */

const moduleAlias = [aliasList];

const modules = {
  auth: moduleAuth,
  alias: moduleAlias,
};

export default modules;
