import React, { Fragment, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";

import Popover from "@material-ui/core/Popover";
import CircularProgress from "@material-ui/core/CircularProgress";

/*
: properties
children
size
fullWidth
*/

// const useStyles = makeStyles((theme) => ({
//   root: {
//     "& .MuiPaper-root": {
//       display: "flex !important",
//       flexDirection: "column !important",
//     },
//   },
// }));

const Index = (props) => {
  //   const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [popupDisplayId, setPopupDisplayId] = useState(null);

  const open = Boolean(anchorEl);
  const idOpen = open ? "simple-popover" : undefined;

  //   const handleOpen = (event, id) => {
  //     event.stopPropagation();
  //     setAnchorEl(event.currentTarget);
  //     setPopupDisplayId(id);
  //   };

  //   const handleClose = (e) => {
  //     e.stopPropagation();
  //     setAnchorEl(null);
  //     setPopupDisplayId(null);
  //   };

  const { text, onClick, disabled, isLoading, style } = props;

  return (
    <Fragment>
      <Button
        {...props}
        key={0}
        variant="contained"
        color="primary"
        type="submit"
        onClick={(e) => {
          //   handleClose(e);
          onClick(e);
        }}
        style={{
          ...style,
          borderStyle: "solid",
          borderColor: "#ffffff",
        }}
        disableElevation={true}
      >
        {isLoading ? (
          <CircularProgress size={25} style={{ color: "#ffffff" }} />
        ) : (
          text
        )}
      </Button>
    </Fragment>
  );
};

export default Index;
