import Normal from "./normal";
import NormalRed from "./normalRed";
import NormalRedV2 from "./normalRedV2";
import NormalText from "./normalText";
import Primary from "./primary";
import Text from "./text";
import Box from "./box";

export default {
  Normal,
  NormalRed,
  NormalRedV2,
  NormalText,
  Primary,
  Text,
  Box,
};
