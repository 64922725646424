const defaultRole = 0;
const manager = (LocalStorage) => {
  // roles = 0 = guest user
  // roles = 1 = super user

  try {
    // check if token exist in login LS
    const { cognito } = LocalStorage.getLS();
    // console.log(cognito)
    if (!cognito) throw "no login";
    if (cognito.role === "creator+approver+publisher") return 1;

    return 0;
  } catch (e) {
    return defaultRole;
  }
};

export default manager;
