import setupurllive from "./setup-url-live";
import setupurluat from "./setup-url-uat";

// let setup = setupurllive
let setup = setupurluat;
// if (process.env.REACT_APP_BUILD_ENV === "production") setup = setupurllive;

export default {
  project: "Subway Rewards",
  name: "Subway Rewards",
  url: "",
  auth_landing_module: "/ordering",
  idle_limit: 300000, //5 mins,
  domain_prefix: "https://subwayrewards.",
  dev_env_token: "rceotaetvitetvsvcnekentadeo",
  default_country: "ie",
  default_lang: "en",
  ...setup,
};
