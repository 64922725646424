import React, { useContext, Fragment, useState, useEffect } from "react";
import moment from "moment";

/* package */
import { SectionWrappListItem } from "../../lib-atomic-section";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import {
  WindowSize,
  FetchContextApiV2,
  LocalStorage,
} from "../../lib-atomic-functions";
import { GlobalContext } from "../../lib-atomic-context";
import { Buttons } from "../../lib-atomic";
import { useSnackbar } from "notistack";
/* package */

/* modules */
import CONFIG from "../config";

import CheckPopup from "../popup";

import SectionContentFilter from "../../app-filter-section-select-alias/default";
import Spinner from "../../app-module-section-spinner/default";
/* CONSTANTS */
// const OBJECTS = CONFIG.application.objects;
const ENDPOINTS = CONFIG.application.api.endpoints;
const TERMINATEALIASCONFIG = {
  url: ENDPOINTS.terminatealias.url,
  method: ENDPOINTS.terminatealias.method,
  headers: ENDPOINTS.terminatealias.headers,
  responseType: "json",
};
/* CONSTANTS */

const useStyles = makeStyles((theme) => ({
  root: {
    "&:hover": {
      cursor: "pointer",
    },
    height: 170,
  },
  upper: {
    textTransform: "uppercase",
  },
  camel: {
    textTransform: "capitalize",
  },
}));

const formatDate = (from, to) => {
  return {
    formattedFrom:
      from !== null && from !== ""
        ? moment(from).format("DD-MMM-YYYY  HH:mm:ss")
        : "Not set",
    formattedTo:
      to !== null && to !== ""
        ? moment(to).format("DD-MMM-YYYY  HH:mm:ss")
        : "Not set",
  };
};
const Index = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { width, height } = WindowSize();
  const classes = useStyles();
  const { terminatealias } = useContext(GlobalContext);
  const { token } = LocalStorage.getLS().cognito;

  const [loading, setLoading] = useState(false);
  const [rawContent, setRawContent] = useState([]);
  const [filteredContent, setFilteredContent] = useState([]);

  const [deletePopup, setDeletePopup] = useState(false);
  const [idInAction, setIdInAction] = useState(null);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const fetchTerminateAliasExecute = async ({ query }) => {
    TERMINATEALIASCONFIG.headers.Authorization = token;
    const [fetchTerminateAlias] = FetchContextApiV2(TERMINATEALIASCONFIG);
    terminatealias.dispatch({ type: "LOAD" });
    const fetchResult = await fetchTerminateAlias({ query }, terminatealias);
    terminatealias.dispatch({
      type: "SET",
      data: fetchResult,
    });
  };

  const resetTerminateAliasExecute = async () => {
    terminatealias.dispatch({
      type: "RESET",
    });
  };

  const reloadPage = () => {
    props.history.go(0);
  };

  useEffect(() => {
    // const mastercampaign = campaignlist.state.data;
    // setRawContent(mastercampaign);
    // setFilteredContent(mastercampaign);
  }, []);
  const doReset = async () => {
    const result = terminatealias.state.data;
    setRawContent(result);
    setFilteredContent(result);
    await resetTerminateAliasExecute();

    setLoadingDelete(false);
    const message = "The card has been successfully deleted :)";
    enqueueSnackbar(message, {
      variant: "success",
    });
  };
  const failedReset = async () => {
    setRawContent([]);
    setFilteredContent([]);
    await resetTerminateAliasExecute();

    setLoadingDelete(false);
    const message = "The card deletion unsuccessful :(";
    enqueueSnackbar(message, {
      variant: "warning",
    });
  };
  useEffect(() => {
    if (
      loadingDelete &&
      terminatealias.state.data &&
      terminatealias.state.isStatus &&
      !terminatealias.state.isLoading
    ) {
      doReset();
      // reloadPage();
    } else if (
      loadingDelete &&
      !terminatealias.state.isStatus &&
      !terminatealias.state.isLoading
    ) {
      failedReset();
    }
  }, [terminatealias.state]);

  useEffect(() => {
    if (!deletePopup && idInAction !== null) {
      setDeletePopup(!deletePopup);
    }
  }, [idInAction]);

  const deleteFunc = async () => {
    setDeletePopup(!deletePopup);
    setLoadingDelete(true);
    console.log("delete..", idInAction);
    await fetchTerminateAliasExecute({
      query: "/" + idInAction.id + "?userId=" + idInAction.trader_id,
    });
  };

  const propsForDeletePopup = {
    ...props,
    toggle: deletePopup,
    setToggle: setDeletePopup,
    action: deleteFunc,
    cancelAction: () => {
      setIdInAction(null);
      setDeletePopup(!deletePopup);
    },
  };

  // data props
  const filterProps = {
    ...props,
    rawContent,
    filteredContent,
    setFilteredContent,
    loading,
    setLoading,
  };
  // data props

  return (
    <Fragment>
      <SectionContentFilter {...filterProps} />

      <CheckPopup {...propsForDeletePopup} />
      {loading || loadingDelete ? (
        <Spinner.Loader
          color="#fff"
          size={24}
          loading={loading || loadingDelete}
        />
      ) : (
        <Fragment>
          {filteredContent.length === 0 && (
            <SectionWrappListItem
              key={"0"}
              noOfCol={width < 820 ? 12 : width < 1150 ? 6 : 3}
              customStyle={{
                cursor: "pointer",
                // overflow: "auto",
                display: "block",
              }}
              onClickFunc={() => {}}
            >
              {/* <SectionContentImage
                {...{
                  images: [{ name: null, img: null }],
                  size: "small",
                }}
              /> */}
              <div
                className={classes.root}
                style={{ textAlign: "center", marginTop: 15 }}
              >
                <Typography variant="body2">
                  <b>No Search Result</b>
                </Typography>
              </div>
            </SectionWrappListItem>
          )}
          {filteredContent.map((item, index) => {
            console.log(item);
            const {
              card_expiry_date,
              card_token,
              card_type,
              creation_date,
              display_text,
              fl_active,
              id,
              trader_id,
            } = item;

            const deleteBtnProps = {
              text: "TERMINATE",
              onClick: (e) => {
                e.stopPropagation();
                setIdInAction(item);
              },
              disabled: !fl_active,
              isLoading: loadingDelete && idInAction === item.id,
              // style: {
              //   fontSize: 10,
              //   width: "auto",
              // },
            };
            // const { formattedFrom, formattedTo } = formatDate(
            //   publishFrom,
            //   publishTo,
            // );

            return (
              <SectionWrappListItem
                key={index}
                noOfCol={width < 820 ? 12 : width < 1150 ? 6 : 3}
                customStyle={{
                  cursor: "pointer",
                  // overflow: "auto",
                  display: "block",
                }}
                // onClickFunc={() => handleToDetail({ id })}
              >
                {/* <Typography variant="body2">
                  <small className={classes.upper}>
                    <b>{mastercountries.abbr}</b>
                  </small>
                  &nbsp; / &nbsp;
                  <small className={classes.camel}>
                    <b>{masterlanguages.abbr}</b>
                  </small>
                </Typography> */}
                {/* <SectionContentImage {...imageData} /> */}
                <div className={classes.root}>
                  <Typography variant="body2">
                    <b>#{id}</b>
                  </Typography>
                  <Typography variant="body2">
                    <small>
                      <span>Card Type</span>
                      <b>&nbsp;&nbsp;{card_type}</b>
                    </small>
                    <br />
                    <small>
                      <span>Expiry Date</span>
                      <b>&nbsp;&nbsp;{card_expiry_date}</b>
                    </small>
                    <br />
                    <small>
                      <span>Display Text</span>
                      <b>&nbsp;&nbsp;{display_text}</b>
                    </small>
                    <br />
                    <br /> <Buttons.NormalRedV2 {...deleteBtnProps} />
                  </Typography>
                  {/* <Typography variant="body2">
                  </Typography> */}
                </div>
              </SectionWrappListItem>
            );
          })}
        </Fragment>
      )}
    </Fragment>
  );
};

export default Index;
