import appConf from "../app-conf";
const setup = appConf[process.env.REACT_APP_CLIENT];
const env = process.env.NODE_ENV;
const config = {
  details: {
    env,
  },
  application: {
    api: {
      endpoints: {
        aliaslist: {
          headers: {
            "Content-Type": "application/json",
          },
          url: setup.setup.api.alias.url[env] + "/cards",
          // url: setup.setup.api.cms.url[env] + '/auth/local',
          method: "GET",
        },
      },
    },
    objects: {
      searchtext: {
        name: "searchtext",
        initial: "",
        placeholder: "Search user ID",
        helperText: false,
        rules: false,
      },
    },
  },
  routes: {
    label: false,
    prefix: false,
  },
  env,
  setup,
};

export default config;
