import React, { useContext, Fragment, useState, useEffect } from "react";
import moment from "moment";
/* package */
import Popover from "@material-ui/core/Popover";
import { makeStyles } from "@material-ui/core/styles";
import { Buttons, Inputs } from "../../lib-atomic";
import {
  FormHook,
  WindowSize,
  FetchContextApiV2,
  LocalStorage,
} from "../../lib-atomic-functions";
import { GlobalContext } from "../../lib-atomic-context";
/* package */

/* modules */
import CONFIG from "../config";

/* CONSTANTS */

const OBJECTS = CONFIG.application.objects;
const ENDPOINTS = CONFIG.application.api.endpoints;
const ALIASLISTCONFIG = {
  url: ENDPOINTS.aliaslist.url,
  method: ENDPOINTS.aliaslist.method,
  headers: ENDPOINTS.aliaslist.headers,
  responseType: "json",
};
/* CONSTANTS */

const Index = (props) => {
  const { width, height } = WindowSize();
  const { cognitouser, aliaslist } = useContext(GlobalContext);
  const { token } = LocalStorage.getLS().cognito;
  // const [loading, setLoading] = useState(false);
  const { rawContent, setFilteredContent, loading, setLoading } = props;

  /* --- cognitouser */
  const fetchAliasListExecute = async ({ query }) => {
    ALIASLISTCONFIG.headers.Authorization = token;
    const [fetchAliasList] = FetchContextApiV2(ALIASLISTCONFIG);
    aliaslist.dispatch({ type: "LOAD" });
    const fetchResult = await fetchAliasList({ query }, aliaslist);
    aliaslist.dispatch({
      type: "SET",
      data: fetchResult,
    });
  };
  /* --- cognitouser */

  useEffect(() => {
    if (
      loading &&
      aliaslist.state.data &&
      aliaslist.state.isStatus &&
      !aliaslist.state.isLoading
    ) {
      console.log(aliaslist.state.data);
      setFilteredContent(aliaslist.state.data);
      setLoading(false);
    }
    if (
      loading &&
      aliaslist.state.data &&
      !aliaslist.state.isStatus &&
      !aliaslist.state.isLoading
    ) {
      console.log("failed");
      props.history.push("/auth/logout");
    }
  }, [aliaslist.state]);

  const handleSearch = async () => {
    console.log(values);
    if (!loading) {
      setLoading(true);
      fetchAliasListExecute({ query: "?userId=" + values.searchtext });
    }
  };
  const { values, onChange, onSubmit, error } = FormHook.useFormHook(
    handleSearch,
    OBJECTS,
  );

  const searchtext = {
    ...OBJECTS.searchtext,
    error: error.searchtext,
    value: values.searchtext,
    onChange,
  };
  const submitButton = {
    fullWidth: true,
    style: {
      maxHeight: 40,
      margin: "auto",
      // marginTop: 8,
      // marginBottom: 6,
      // marginLeft: 10,
      // marginRight: 10,
    },
    // disabled: !showCreateBtn,
    onClick: () => {
      // props.history.push('/campaign/create')
      handleSearch();
    },
  };
  return (
    <Fragment>
      <div style={{ width: "100%" }}>
        <form onSubmit={onSubmit} noValidate>
          <div
            style={{
              // display: width < 1200 ? "inline" : "flex",
              display: "flex",
              // height: "100%",
              width: "100%",
              // margin: 'auto',
              // marginRight: width < 1200 ? (width < 600 ? 'auto' : 'auto') : 'auto',
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ width: "80%" }}>
              <Inputs.Text {...searchtext} />
            </div>
            &nbsp;&nbsp;&nbsp;
            <div style={{ width: "20%" }}>
              <Buttons.Normal {...submitButton}>Search</Buttons.Normal>
            </div>
          </div>
        </form>
      </div>
    </Fragment>
  );
};

export default Index;
