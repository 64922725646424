import React from "react";

/* package */
import { SectionWrappList } from "../../lib-atomic-section";
/* package */

/* container */
import AliasContainer from "../../app-alias-container/default";
/* container */

/* form */
import Content from "../layout/content";
/* form */

const index = (props) => {
  return (
    <AliasContainer.AliasList {...props}>
      <SectionWrappList {...props}>
        <Content {...props} />
      </SectionWrappList>
    </AliasContainer.AliasList>
  );
};

export default index;
