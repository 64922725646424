import React from "react";
import { makeStyles } from "@material-ui/core/styles";

/* container */
import { LocalStorage, WindowSize } from "../../lib-atomic-functions";
/* container */

/* modules */
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Buttons } from "../../lib-atomic";
/* modules */

import CONFIG from "../config";

/* constant */
const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "auto",
    width: "55%",
    minWidth: 300,
    maxWidth: 300,
    borderRadius: 8,
  },
  paper: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    border: "0px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    borderRadius: 10,
    margin: "auto",
    textAlign: "center",
    "&focus": {
      outline: "none",
    },
  },
}));
/* constant */

const Index = (props) => {
  // console.log("popup!");
  const { width, height } = WindowSize();
  const { toggle, setToggle, action, cancelAction } = props;
  const classes = useStyles();

  const submitButton = {
    fullWidth: true,
    style: {
      borderRadius: 8,
      paddingLeft: 25,
      paddingRight: 25,
      height: 30,
      fontSize: 10,
    },
  };
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={toggle}
      onClose={() => cancelAction()}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 700,
      }}
    >
      <Fade in={toggle}>
        <div className={classes.paper}>
          <h3
            id="transition-modal-title"
            style={{
              paddingBottom: 40,
              fontSize: width < 500 ? 17 : 25,
            }}
          >
            Are you sure ?
          </h3>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div
              style={{
                width: 150,
                margin: "auto",
                marginLeft: 10,
                marginRight: 10,
              }}
              onClick={() => {
                cancelAction();
              }}
            >
              <Buttons.Text {...submitButton}>CANCEL</Buttons.Text>
            </div>
            <div
              style={{
                width: 150,
                margin: "auto",
                marginLeft: 10,
                marginRight: 10,
              }}
              onClick={() => {
                action();
              }}
            >
              <Buttons.NormalRed {...submitButton}>DELETE</Buttons.NormalRed>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default Index;
