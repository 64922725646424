import React, { useEffect, useContext } from "react";

import { GlobalContext } from "../../lib-atomic-context";
import {
  FetchContextApi,
  FetchContextApiV2,
  LocalStorage,
} from "../../lib-atomic-functions";

/* module */
import Spinner from "../../app-module-section-spinner/default";
/* module */

import CONFIG from "../config";

const CMSENDPOINTS = CONFIG.application.cms.api.endpoints;
const COGNITOAUTHUSER = {
  url: CMSENDPOINTS.mastercongnitouser.url,
  method: CMSENDPOINTS.mastercongnitouser.method,
  headers: CMSENDPOINTS.mastercongnitouser.headers,
  responseType: "json",
};

const Index = (props) => {
  // console.log(LocalStorage.getLS())
  // const { token, username } = LocalStorage.getLS().cognito
  // const {
  //   logon,
  //   country,
  //   role,
  //   authuser,
  //   cognitouser,
  //   cognitouserlist,
  //   updatecognitouser,
  //   countrylanguage,
  //   contents,
  //   allcontents,
  //   contentdetail,
  //   contentcarouseldetail,
  //   createcontents,
  //   uploadhomeimage,
  //   createcontent,
  //   updatecontent,
  //   deletecontent,
  //   mastersetups,
  //   mastersetupspartner,
  //   createmastersetup,
  //   mastercontentsversions,
  //   masterboxes,
  //   updatesetup,
  //   masterbanners,
  //   updatemasterbanners,
  //   masternotes,
  // } = useContext(GlobalContext)

  // const fetchAuthUserExec = async ({ query }) => {
  //   console.log(authuser.state, ' authuser.state')
  //   COGNITOAUTHUSER.headers.Authorization = 'Bearer ' + token
  //   const [fetchAuthUser] = FetchContextApiV2(COGNITOAUTHUSER)
  //   authuser.dispatch({ type: 'LOAD' })
  //   const fetchResult = await fetchAuthUser({ query }, authuser)
  //   authuser.dispatch({
  //     type: 'SET',
  //     data: fetchResult,
  //   })
  // }

  // useEffect(() => {
  //   const query = '?cognitoId=' + username
  //   fetchAuthUserExec({ query })
  // }, [])

  // useEffect(() => {
  //   if (
  //     cognitouser.state.data &&
  //     !cognitouser.state.isStatus &&
  //     !cognitouser.state.isLoading &&
  //     cognitouser.state.data.response.status === 401
  //   ) {
  //     goToLogout()
  //   } else {
  //     // console.log(contents.state.data.response.status)
  //     const arrOfContext = [
  //       logon,
  //       country,
  //       role,
  //       cognitouserlist,
  //       updatecognitouser,
  //       countrylanguage,
  //       contents,
  //       allcontents,
  //       contentdetail,
  //       contentcarouseldetail,
  //       createcontents,
  //       uploadhomeimage,
  //       createcontent,
  //       updatecontent,
  //       deletecontent,
  //       mastersetups,
  //       mastersetupspartner,
  //       createmastersetup,
  //       mastercontentsversions,
  //       masterboxes,
  //       updatesetup,
  //       masterbanners,
  //       updatemasterbanners,
  //       masternotes,
  //     ]
  //     const checkExpire = arrOfContext.some(
  //       (contextItem) =>
  //         contextItem.state.data &&
  //         !contextItem.state.isStatus &&
  //         !contextItem.state.isLoading &&
  //         contextItem.state.data.response.status === 401
  //     )
  //     if (checkExpire) {
  //       goToLogout()
  //     }
  //   }
  // }, [
  //   logon.state,
  //   country.state,
  //   role.state,
  //   cognitouser.state,
  //   cognitouserlist.state,
  //   updatecognitouser.state,
  //   countrylanguage.state,
  //   contents.state,
  //   allcontents.state,
  //   contentdetail.state,
  //   contentcarouseldetail.state,
  //   createcontents.state,
  //   uploadhomeimage.state,
  //   createcontent.state,
  //   updatecontent.state,
  //   deletecontent.state,
  //   mastersetups.state,
  //   mastersetupspartner.state,
  //   createmastersetup.state,
  //   mastercontentsversions.state,
  //   masterboxes.state,
  //   updatesetup.state,
  //   masterbanners.state,
  //   updatemasterbanners.state,
  //   masternotes.state,
  // ])

  // const goToLogout = () => {
  //   props.history.push('/auth/tokenexpired')
  // }

  // const isAuthUserReady =
  //   authuser.state.data && authuser.state.isStatus && !authuser.state.isLoading
  //     ? true
  //     : false

  // if (!isAuthUserReady) return null

  return props.children;
};
export default Index;
