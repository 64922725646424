import appConf from "../app-conf";
const setup = appConf[process.env.REACT_APP_CLIENT];
const environment = process.env.NODE_ENV;
const project = "Change Password";
const server = null;
const service = null;

const config = {
  details: {
    project,
    server,
    environment,
    service,
  },
  application: {
    api: {
      endpoints: {
        terminatealias: {
          headers: {
            "Content-Type": "application/json",
          },
          url: setup.setup.api.alias.url[environment] + "/cards",
          // url: setup.setup.api.cms.url[env] + '/auth/local',
          method: "DELETE",
        },
      },
    },
    objects: {
      platform: {
        name: "platform",
        initial: "",
        label: "Platform",
        type: "select",
        options: [],
        helperText: false,
        rules: false,
      },
      boxobjects: {
        name: "boxobjects",
        initial: "",
        label: "Box",
        type: "select",
        options: [],
        helperText: false,
        rules: false,
      },
      contentselect: {
        name: "contentselect",
        initial: "",
        label: "Contents",
        type: "select",
        options: [],
        helperText: false,
        rules: false,
      },
    },
    responses: {
      fail: "Incorrect email or password",
      unverified: "Please verify your account by ticking the recaptcha.",
    },
  },
  routes: {
    label: "Alias List",
    prefix: "/alias/list",
    // prefix: "/:country/:lang/drag_and_drop"
  },
  environment,
  setup,
};

export default config;
