import React from "react";

import AliasListContainer from "../sub-container/alias-list-container";

const AliasList = (props) => {
  return <AliasListContainer {...props}>{props.children}</AliasListContainer>;
};

export default {
  AliasList,
};
