import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import { LocalStorage } from "../../lib-atomic-functions";

const useStyles = makeStyles((theme) => ({
  username: {
    textAlign: "right",
  },
}));

const Index = (props) => {
  const classes = useStyles();

  const { cognito } = LocalStorage.getLS();

  if (!cognito) {
    props.history.push("/auth/logout");
    return false;
  }

  const { username } = cognito;

  const today = new Date();
  const date =
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
  const time =
    today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();

  return (
    <div className={classes.username}>
      <span>
        <b>{username}</b> <br />
        <small>
          {date} {time}
        </small>
      </span>
    </div>
  );
};
export default Index;
