import React from "react";

/* packages */
import Privilege from "../app-conf-privilege";
import { LocalStorage } from "../lib-atomic-functions";
/* packages */

const Routes = (props) => {
  const { rolesHandler, rolesSubModules } =
    Privilege[process.env.REACT_APP_CLIENT];
  const availableModules = rolesHandler(LocalStorage, rolesSubModules);

  return availableModules.alias.map((Module, i) => {
    return <Module.Routes key={i} />;
  });
};
export default Routes;
