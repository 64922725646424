import appConf from "../app-conf";
const setup = appConf[process.env.REACT_APP_CLIENT];
const env = process.env.NODE_ENV;
const config = {
  details: {
    env,
  },
  application: {
    api: {
      endpoints: {
        auth: {
          headers: {
            "Content-Type": "application/json",
            Authorization: null,
          },
          url: setup.setup.api.login.url[env],
          //url: 'https://figsvqulre.execute-api.ap-southeast-2.amazonaws.com/production/stormborn-gui-strapi-authorization',
          // url: 'https://5fj7g7llff.execute-api.ap-southeast-2.amazonaws.com/production/stormborn-gui-strapi-authorization-live',
          ///////////////////////////// url: 'https://108n35p3z6.execute-api.ap-southeast-2.amazonaws.com/production/stormborn-gui-strapi-live',

          method: "GET",
        },
      },
    },
    objects: {
      username: {
        name: "username",
        // initial: '',
        initial: "",
        placeholder: "Username",
        helperText: false,
        rules: false,
      },
      password: {
        name: "password",
        // initial: '',
        initial: "",
        placeholder: "Password",
        helperText: false,
        rules: false,
      },
    },
    responses: {
      authFail: "Incorrect email or password",
    },
  },
  routes: {
    label: "Login",
    prefix: "/auth/login",
  },
  env,
  setup,
};

export default config;
