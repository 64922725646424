import React from "react";

import LoginContainer from "../sub-container/login-container";
const Login = (props) => {
  return <LoginContainer {...props}>{props.children}</LoginContainer>;
};

export default {
  Login,
};
